import { combineReducers } from 'redux';

import windowDimensionReducer from './windowDimensionReducer/windowDimensionReducer';
import loaderReducer from './loaderReducer/loaderReducer';
import newsReducer from './newsReducer/newsReducer';
import geoReducer from './geoReducer/geoReducer';

const rootReducer = combineReducers({
  windowRXS: windowDimensionReducer,
  loaderRXS: loaderReducer,
  newsRXS: newsReducer,
  geoRXS: geoReducer,
});

export default rootReducer;
