import { BlogSummaryTypes } from '@src/typescriptGlobals/contentful.types';
import { NewsPageQueryResponseTypes, PaginatedNewsPageQueryResponseTypes } from '@src/graphql/queries.types';

import { NewsDispatchTypes } from '@src/redux/actions/newsActions/newsActions.types';
import { ActionTypes } from '@src/redux/actions';

import { DefaultStateTypes } from './newsReducer.types';

const defaultState: DefaultStateTypes = {
  isLoading: false,
  latestNewsData: {} as BlogSummaryTypes,
  newsData: {} as NewsPageQueryResponseTypes,
  paginatedNewsData: {} as PaginatedNewsPageQueryResponseTypes,
};

const newsReducer = (state = defaultState, action: NewsDispatchTypes) => {
  switch (action.type) {
    case ActionTypes.NEWS_TOGGLE_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case ActionTypes.FETCH_LATEST_NEWS_DATA:
      return {
        ...state,
        latestNewsData: action.payload,
      };
    case ActionTypes.FETCH_NEWS_DATA:
      return {
        ...state,
        newsData: action.payload,
      };
    default:
      return state;
  }
};

export default newsReducer;
