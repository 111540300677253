import { ActionTypes } from '@src/redux/actions';
import { DefaultStateTypes } from './geoReducer.types';
import { GeoDispatchTypes } from '@src/redux/actions/geoActions/geoActions.types';

const defaultState: DefaultStateTypes = {
  country: null,
  locale: null,
};

const userLocationReducer = (state = defaultState, action: GeoDispatchTypes) => {
  switch (action.type) {
    case ActionTypes.SET_COUNTRY:
      return {
        ...state,
        country: action.payload,
      };
    case ActionTypes.SET_LOCALE:
      return {
        ...state,
        locale: action.payload,
      };
    default:
      return state;
  }
};

export default userLocationReducer;
